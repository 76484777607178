import React, { useEffect, useState } from 'react';
import '../styles/Matches.css'; // Optionale CSS-Datei für Styling
import getMatches from '../functions/matches/getMatches';
import handleResultChange from '../functions/matches/handleResultChange';
import updateBetWin from '../functions/matches/updateBetWin';

const Matches = () => {
    const [countries, setCountries] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [teams, setTeams] = useState([]);
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [filterCountryId, setFilterCountryId] = useState('');
    const [filterCountryName, setFilterCountryName] = useState('');
    const [filterLeagueId, setFilterLeagueId] = useState('');
    const [filterLeagueName, setFilterLeagueName] = useState('');
    const [filterSeasonId, setFilterSeasonId] = useState('');
    const [filterSeasonName, setFilterSeasonName] = useState('');
    const [filterTeamId, setFilterTeamId] = useState('');
    const [filterTeamName, setFilterTeamName] = useState('');
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - 14);
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + 14);

        setFilterStartDate(startDate.toISOString().split('T')[0]);
        setFilterEndDate(endDate.toISOString().split('T')[0]);

        getMatches(setCountries, setLeagues, setSeasons, setTeams, setMatches, setLoading, setError);
    }, []);

    const handleResultChangeWrapper = (id, result) => {
        handleResultChange(id, result, () => getMatches(setCountries, setLeagues, setSeasons, setTeams, setMatches, setLoading, setError), setError);
    };

    const handleBetWinChange = async (id, betWin) => {
        await updateBetWin(id, betWin, () => getMatches(setCountries, setLeagues, setSeasons, setTeams, setMatches, setLoading, setError), setError);
    };

    const handleResetFilters = () => {
        setFilterCountryId('');
        setFilterCountryName('');
        setFilterLeagueId('');
        setFilterLeagueName('');
        setFilterSeasonId('');
        setFilterSeasonName('');
        setFilterTeamId('');
        setFilterTeamName('');

        const today = new Date();
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - 14);
        const endDate = new Date(today);
        endDate.setDate(today.getDate() + 14);

        setFilterStartDate(startDate.toISOString().split('T')[0]);
        setFilterEndDate(endDate.toISOString().split('T')[0]);

        getMatches(setCountries, setLeagues, setSeasons, setTeams, setMatches, setLoading, setError);
    };

    const handleCountryChange = (e) => {
        const selectedCountryId = e.target.value;
        setFilterCountryId(selectedCountryId);
        const selectedCountry = countries.find(country => country.id === parseInt(selectedCountryId));
        setFilterCountryName(selectedCountry ? selectedCountry.name : '');
    };

    const handleLeagueChange = (e) => {
        const selectedLeagueId = e.target.value;
        setFilterLeagueId(selectedLeagueId);
        const selectedLeague = leagues.find(league => league.id === parseInt(selectedLeagueId));
        setFilterLeagueName(selectedLeague ? selectedLeague.name : '');
        setFilterSeasonId('');
        setFilterSeasonName('');
        setFilterTeamId('');
        setFilterTeamName('');
    };

    const handleSeasonChange = (e) => {
        const selectedSeasonId = e.target.value;
        setFilterSeasonId(selectedSeasonId);
        const selectedSeason = seasons.find(season => season.id === parseInt(selectedSeasonId));
        setFilterSeasonName(selectedSeason ? selectedSeason.name : '');
        setFilterTeamId('');
        setFilterTeamName('');
    };

    const handleTeamChange = (e) => {
        const selectedTeamId = e.target.value;
        setFilterTeamId(selectedTeamId);
        const selectedTeam = teams.find(team => team.id === parseInt(selectedTeamId));
        setFilterTeamName(selectedTeam ? selectedTeam.name : '');
    };

    const applyFilters = () => {
        return matches.filter(match => {
            const matchDate = new Date(match.matchdate);
            const startDate = filterStartDate ? new Date(filterStartDate) : null;
            const endDate = filterEndDate ? new Date(filterEndDate) : null;

            return (
                (!filterCountryName || match.country === filterCountryName) &&
                (!filterLeagueName || match.league === filterLeagueName) &&
                (!filterSeasonName || match.season === filterSeasonName) &&
                (!filterTeamName || match.home_team === filterTeamName || match.away_team === filterTeamName) &&
                (!startDate || matchDate >= startDate) &&
                (!endDate || matchDate <= endDate)
            );
        });
    };

    const filteredMatches = applyFilters();

    const filteredLeaguesForFilter = leagues.filter(league => league.country_id === parseInt(filterCountryId));
    const filteredSeasonsForFilter = filteredLeaguesForFilter.length > 0 ? seasons.filter(season => season.league_id === parseInt(filterLeagueId)) : [];
    const filteredTeamsForFilter = filteredSeasonsForFilter.length > 0 ? teams.filter(team => team.season_id === parseInt(filterSeasonId)) : [];

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('de-DE', options);
    };


    const calculateAndDisplayWinChances = (homeChance, awayChance) => {
        const formattedHomeChance = homeChance !== null && !isNaN(homeChance) ? Number(homeChance).toFixed(2) + '%' : '';
        const formattedAwayChance = awayChance !== null && !isNaN(awayChance) ? Number(awayChance).toFixed(2) + '%' : '';
        return (
            <>
                <td>{formattedHomeChance}</td>
                <td>{formattedAwayChance}</td>
            </>
        );
    };

    const shouldDisplayBetWinDropdown = (homeChance, awayChance) => {
        return (homeChance >= 55) || (awayChance >= 55);
    };

    const mapBetWinToDisplay = (betwin) => {
        switch (betwin) {
            case '1': return 'Ja';
            case '0': return 'Nein';
            case 'x': return 'Unentschieden';
            default: return '';
        }
    };

    const mapDisplayToBetWin = (displayValue) => {
        switch (displayValue) {
            case 'Ja': return '1';
            case 'Nein': return '0';
            case 'Unentschieden': return 'x';
            default: return '';
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data: {error.message}</p>;

    return (
        <div>
            <h1>Spiele</h1>
            <div className="filter-container">
                <select value={filterCountryId} onChange={handleCountryChange}>
                    <option value="">Land filtern</option>
                    {countries.map(country => (
                        <option key={country.id} value={country.id}>{country.name}</option>
                    ))}
                </select>
                <select value={filterLeagueId} onChange={handleLeagueChange} disabled={!filterCountryId}>
                    <option value="">Liga filtern</option>
                    {filteredLeaguesForFilter.map(league => (
                        <option key={league.id} value={league.id}>{league.name}</option>
                    ))}
                </select>
                <select value={filterSeasonId} onChange={handleSeasonChange} disabled={!filterLeagueId}>
                    <option value="">Season filtern</option>
                    {filteredSeasonsForFilter.map(season => (
                        <option key={season.id} value={season.id}>{season.name}</option>
                    ))}
                </select>
                <select value={filterTeamId} onChange={handleTeamChange} disabled={!filterSeasonId}>
                    <option value="">Mannschaft filtern</option>
                    {filteredTeamsForFilter.map(team => (
                        <option key={team.id} value={team.id}>{team.name}</option>
                    ))}
                </select>
                <input
                    type="date"
                    value={filterStartDate}
                    onChange={(e) => setFilterStartDate(e.target.value)}
                    placeholder="Startdatum"
                />
                <input
                    type="date"
                    value={filterEndDate}
                    onChange={(e) => setFilterEndDate(e.target.value)}
                    placeholder="Enddatum"
                />
                <button onClick={handleResetFilters}>Filter zurücksetzen</button>
            </div>
            <div className="match-table">
                <table>
                    <thead>
                    <tr>
                        <th>Datum</th>
                        <th>Heim</th>
                        <th>Auswärts</th>
                        <th>Land</th>
                        <th>Liga</th>
                        <th>Season</th>
                        <th>Spieltag</th>
                        <th>Heim-Chance</th>
                        <th>Auswärts-Chance</th>
                        <th>Ergebnis</th>
                        <th>Wette</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredMatches.sort((a, b) => new Date(a.matchdate) - new Date(b.matchdate)).map(match => (
                        <tr key={match.id}>
                            <td>{formatDate(match.matchdate)}</td>
                            <td>{match.home_team}</td>
                            <td>{match.away_team}</td>
                            <td>{match.country}</td>
                            <td>{match.league}</td>
                            <td>{match.season}</td>
                            <td>{match.gameday}</td>
                            {calculateAndDisplayWinChances(match.homechance, match.awaychance)}
                            <td>
                                <select onChange={(e) => handleResultChangeWrapper(match.id, e.target.value)}
                                        value={match.result || ''}>
                                    <option value="">Ergebnis wählen</option>
                                    <option value="Heimsieg">Heimsieg</option>
                                    <option value="Auswärtssieg">Auswärtssieg</option>
                                    <option value="Unentschieden">Unentschieden</option>
                                    <option value="Spiel verschoben">Spiel verschoben</option>
                                </select>
                            </td>
                            <td>
                                {shouldDisplayBetWinDropdown(match.homechance, match.awaychance) && (
                                    <select
                                        onChange={(e) => handleBetWinChange(match.id, mapDisplayToBetWin(e.target.value))}
                                        value={mapBetWinToDisplay(match.betwin)}>
                                        <option value="">Wette wählen</option>
                                        <option value="Ja">Ja</option>
                                        <option value="Nein">Nein</option>
                                        <option value="Unentschieden">Unentschieden</option>
                                    </select>
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Matches;
